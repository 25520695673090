import * as React from 'react'

import Seo from '../components/Seo'

const NotFoundPage = () => (
  <>
    <Seo />
    <div>
      <h1>404: Not Found</h1>
    </div>
  </>
)

export default NotFoundPage
